import React, {  CSSProperties, useEffect, useState } from 'react';
import {DisplayProcessor, DisplayValue, Field, FieldConfig, FieldType, GrafanaTheme2, PanelProps, ThresholdsMode, getDisplayProcessor } from '@grafana/data';
import { SimpleOptions } from 'types';
import { css, cx } from '@emotion/css';
import { BarGauge, Button, CustomScrollbar, InlineLabel, useStyles2, useTheme2 } from '@grafana/ui';


import { MqttClient, connect } from "mqtt/dist/mqtt"
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, createTheme } from '@mui/material';


interface Props extends PanelProps<SimpleOptions> {}
interface Device{
  Address: number
  CRC: boolean,
}


const getStyles = () => {
  return {
    wrapper: css`
      font-family: Open Sans;
      position: relative;
    `,
    input: css`
      position: relative;
      width: 80%;

    `,
    button: css`
      position: relative;
      padding: 15px;
      margin-left: 2%;
    `,
    li: css`
      display: flex;
      margin-bottom: 5px;
    ` ,
    deleteButton: css`
    display: inline-block;
    padding: 8px 16px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  `,
 tableCell: css`
     display: table-cell;
     padding: 16px; 
     font-size: 0.875rem;
     text-align: left; 
     font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
     font-weight: 400; 
     line-height: 1.43; 
     letter-spacing: 0.01071em; 
     vertical-align: inherit; 
    `
  };
};



export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  
  const [client,setClient] = useState<MqttClient>();
  const [Devices,setDevices] = useState<Device[]>([]);
  const [progress,setProgress] = useState<number>(0);
  //const [__Devices,__setDevices] = useState<Device[]>([]);

  

  function generateRandomClientId(prefix: string, length: number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const randomPart = Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
    return `${prefix}${randomPart}`;
  }


const mqttConnect = (host: string) => {
  const clientIdPrefix = 'client-';
const clientIdLength = 10; // Adjust the length as needed
const randomClientId = generateRandomClientId(clientIdPrefix, clientIdLength);
  //const brokerUrl = 'wss://46.36.40.68:6969';
  //setClient(connect(host));
setClient(
  connect(host, {
clientId: randomClientId,
username: 'vemat',
password: 'vemat1337',
rejectUnauthorized: false
}));
}
// eslint-disable-next-line
useEffect(()=>{
  console.log("F");
    mqttConnect('mqtts://vemat.edima.io:6969');
    //mqttConnect('wss://46.36.40.68:6969');
    console.log("Aktualni");
// eslint-disable-next-line
},[]);

useEffect(() => {
  if (client) {
    console.log(client)
    client.on('connect', () => {
      //setConnectStatus('Connected');
      console.log("Connected");
    });
    client.on('error', (err) => {
      console.error('Connection error: ', err);
      client.end();
    });
    client.on('reconnect', () => {
      //setConnectStatus('Reconnecting');
    });
    client.on('message', (topic, message) => {
      const payload = { topic, message: message.toString() };

      if(topic === "vemat_devices"){
        setDevices(JSON.parse(payload.message));
        document.dispatchEvent(new  CustomEvent('reloadDevices'));
        console.log("dsipatchin event");
      }
      if(topic ==="vemat_search_progress"){
        setProgress(JSON.parse(payload.message));
      }
      //setPayload(payload);
    });
  }
}, [client]);

  
  /*
    const client = connect('mqtt://vemat.edima.io:6969')
    const TOPIC = "VEMAT";
    client.on('connect', () => {
      console.log('Connected to MQTT broker');
      client.subscribe(TOPIC, (err) => {
        if (!err) {
          client.publish(TOPIC, "Hello mqtt");
        }
      });
      // Subscribe to a topic
     
    });
    client.on('message', (topic, message) => {
      console.log(`Received message on topic '${topic}': ${message.toString()}`);
    });
  
    // Handle errors
    client.on('error', (err) => {
      console.error('MQTT error:', err);
    });
  

*/


  //test.mosquitto.org
  //MQTTmqtt://broker.example.com
  //const MQTT_BROKER_URL = 'mqtt://test.mosquitto.org';
  //const MQTT_BROKER_URL = 'mqtt://46.36.40.68:1883'; // Replace with your MQTT broker URL
  
 
  //MQTT
  //const [Devices,setDevices] = useState<Device[]>([]);

  //document.addEventListener("sendD")

  /*
  1. 3 vybery form

  */



function searchDevices(){
  if(client!==undefined){

    let jsonSend = {"command":"searchNewDevices"};
    client.subscribe("VEMAT", (err) => {
      if (!err) {
        client.publish("VEMAT", JSON.stringify(jsonSend));
      }
    });
    client.subscribe("vemat_devices", (err) => {
      if (!err) {
       console.log("kek");
      }});
    client.subscribe("vemat_search_progress",(err)=>{
      if(!err){
        console.log("Ok");
      }
    })


  }
  return 0;
}


  const theme = useTheme2();

  const table_theme = createTheme({
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            color: theme.colors.primary.contrastText,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            color: theme.colors.primary.contrastText,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          body: {
            backgroundColor: theme.colors.background.secondary,
            borderBottom: "0px solid rgba(224, 224, 224, 1)",
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            color: theme.colors.primary.contrastText,
          },
          head: {
            backgroundColor: theme.colors.background.secondary,
            borderBottom: "0px solid rgba(224, 224, 224, 1)",
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            color: theme.colors.primary.contrastText,
          },
          root: {
            backgroundColor: theme.colors.background.secondary,
            borderBottom: "0px solid rgba(224, 224, 224, 1)",
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            color: theme.colors.primary.text,
          },
        },
      },
    },
  });

  const field: Partial<Field> = {
       type: FieldType.number,
       config: {
         min: 0,
         max: 100,
         thresholds: {
            mode: ThresholdsMode.Absolute,
            steps: [
              { value: -Infinity, color: 'green' },
              { value: 40, color: 'red' },
              { value: 60, color: 'yellow' },
            ],
          },
        },
      };
      field.display = getDisplayProcessor({ field, theme });
      type _Props = {
        theme: GrafanaTheme2;
        lcdCellWidth: any;
        itemSpacing: any;
        showUnfilled: any;
        width: any;
        height: any;
        value: DisplayValue;
        displayMode: any;
        orientation: any;
        field: FieldConfig;
        display: DisplayProcessor;
      };
      
      const props: Partial<_Props> = {
        theme,
        lcdCellWidth: 12,
        itemSpacing: 8,
        showUnfilled: false,
        width: 400,
        height: 75,
        value: {
          text: progress.toString(),
          title: "Průběh vyhledávání",
          numeric: progress,
        },
        displayMode: "text",
        orientation: "horizontal",
        field: field.config!,
        display: field.display!,
      };

  const styles = useStyles2(getStyles);
 
  const style_gauge: CSSProperties = {
   
    //fontSize: '28px'
  };
  return (



    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
       
       <Button 
size={"md"}
type="submit"
onClick={()=>searchDevices()}
> Hledat</Button>
<div style={style_gauge}>
 <BarGauge {...props} />
 </div>
    <ThemeProvider theme={table_theme}>
      
<CustomScrollbar>
<TableContainer component={Paper} style={{maxHeight: 1280, backgroundColor: theme.colors.background.primary}}>
<Table >
      <TableHead >
        <TableRow >
          <TableCell>Adresa</TableCell>
          <TableCell>CRC</TableCell>
         
        </TableRow>
      </TableHead>
      <TableBody >
        {Devices.map((row, index) => (
          <TableRow key={index}>
            <TableCell  >

            <InlineLabel>{row.Address.toString()}</InlineLabel> 
             

              
            </TableCell>
          
            <TableCell>
            {row.CRC.toString()}
            </TableCell>
          </TableRow>
          
        ))}
      </TableBody>
     
  
    </Table>
   
  </TableContainer>
  </CustomScrollbar>
  </ThemeProvider>
  
   
        
      
 
       

    </div>
  );
};
